import {Component, inject} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'boffice-root',
  imports: [RouterOutlet, TranslateModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'mybitzer-backoffice';

  translate = inject(TranslateService);

  private lang = this.translate.getBrowserLang()

  constructor() {
    if (this.lang) {
      this.translate.setDefaultLang('de');
      this.translate.use(this.lang);
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
  }

}
