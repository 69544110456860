import { Component } from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  imports: [
    TranslatePipe
  ],
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
}
