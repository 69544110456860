import {
  Routes,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withRouterConfig, withDebugTracing
} from '@angular/router';
import {
  importProvidersFrom, inject,
  provideAppInitializer
} from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi
} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {provideHotToastConfig} from '@ngxpert/hot-toast';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {AuthService} from "@core/auth";
import {initFn} from "@auth/app-init";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";


export interface CoreOptions {
  routes: Routes;
}

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
    new TranslateHttpLoader(http, './assets/i18n/', '.json');

export function provideCore({ routes }: CoreOptions) {
  return [
    provideAnimationsAsync(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always'
      }),
      withDebugTracing(),
      withComponentInputBinding(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      }),
    ),
    // other 3rd party libraries providers like NgRx, provideStore()
    importProvidersFrom([TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    })]),
    provideAnimationsAsync(),
    provideHotToastConfig(),
    importProvidersFrom([KeycloakAngularModule]),
    provideAppInitializer(async (): Promise<void> => {
      const keycloakService = inject(KeycloakService);
      const authService = inject(AuthService);

      await initFn(keycloakService);
      await authService.init();
    }),
    // other application specific providers and setup

    // perform initialization, has to be last


  ];
}
