import { KeycloakOptions, KeycloakService } from "keycloak-angular";
import { environment } from '../../../environments/environment';

export const initFn = ((key: KeycloakService) => {
  let options: KeycloakOptions = {
    config: {
      url: environment.authUrl,
      realm: 'bdn',
      clientId: 'mybitzer-backoffice-app',
    },
    initOptions: {
      onLoad: 'login-required',
      checkLoginIframe: true,
      pkceMethod: 'S256',
    },
    enableBearerInterceptor: true,
    loadUserProfileAtStartUp: true,
    bearerExcludedUrls: ['/assets', '/img'],
  };
  return key.init(options)
});
