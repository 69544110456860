import {Routes} from '@angular/router';


import {
  ACCOUNTING_ADMIN,
  COMPANY_ADMIN,
  CUSTOMER_ADMIN,
  FEEDBACK_ADMIN,
  INGEST_ADMIN,
  SALES_ADMIN,
  SEARCH_ADMIN,
  STOCKLEVEL_IMPORT,
  THING_TEMPLATE_ADMIN
} from '@core/auth';
import {AuthGuard} from '@auth/auth.guard';
import {PageNotFoundComponent} from '@component/page-not-found/page-not-found.component';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./board').then(m => m.MainBoardComponent),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full'
      },
      {
        path: 'search',
        data: { roles: [SEARCH_ADMIN] },
        canActivate: [AuthGuard],
        loadComponent: () => import('./main/search/').then(m => m.SearchComponent),
      },
      {
        path: 'sales',
        data: { roles: [SALES_ADMIN] },
        canActivate: [AuthGuard],
        loadComponent: () => import('./main/sales').then(m => m.SalesComponent),
      },
      {
        path: 'feedback',
        data: { roles: [FEEDBACK_ADMIN] },
        canActivate: [AuthGuard],
        loadComponent: () => import('./main/feedback/').then(m => m.FeedbackComponent),
      },
      {
        path: 'accounting',
        data: { roles: [ACCOUNTING_ADMIN] },
        canActivate: [AuthGuard],
        loadChildren: () => import('./main/accounting').then(m => m.ACCOUNTING_ROUTES),
      },
      {
        path: 'company',
        data: { roles: [COMPANY_ADMIN] },
        canActivate: [AuthGuard],
        loadChildren: () => import('./main/company').then(m => m.COMPANY_ROUTES),
      },
      {
        path: 'customer',
        data: { roles: [CUSTOMER_ADMIN] },
        canActivate: [AuthGuard],
        loadComponent: () => import('./main/customer').then(m => m.CustomerComponent),
      },
      {
        path: 'stock-level',
        data: { roles: [STOCKLEVEL_IMPORT] },
        canActivate: [AuthGuard],
        loadChildren: () => import('./main/stock-level').then(m => m.STOCK_LEVEL_ROUTES),
      },
      {
        path: 'ingest',
        data: { roles: [INGEST_ADMIN] },
        canActivate: [AuthGuard],
        loadChildren: () => import('./main/ingest').then(m => m.INGEST_ROUTES),
      },
      {
        path: 'thing-template',
        data: { roles: [THING_TEMPLATE_ADMIN] },
        canActivate: [AuthGuard],
        loadComponent: () => import('./main/thing-template').then(m => m.ThingTemplateComponent),
      },
      {
        path: '**',
        component: PageNotFoundComponent
      },
    ]
  },
];
